.hero {
  padding-top: 30px;
  padding-bottom: 60px;
}
.contentRow .Button {
  margin-left: 10px;
  margin-right: 10px;
}
.contentRow {
  padding-top: 55px;
}
.buttonSection {
  padding-top: 20px;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
}
.buttonSection > div:first-child {
  display: flex;
  justify-content: flex-end;
}

.subDescriptionText {
  font-size: 16px;
  color: black;
  font-family: "Inter", "Arial", sans-serif;
}
.description {
  padding: 0 120px !important;
  padding-top: 25px !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: black !important;
  font-family: "Inter", "Arial", sans-serif !important;
}
.hero h1 {
  padding-top: 30px !important;
  max-width: 900px;
  margin: 0 auto !important;
  font-family: "Inter", "Arial", sans-serif !important;
}
.linkSection {
  display: flex;
  justify-content: center;
  padding-top: 25px;
}

.dark div[class*="Frame"] svg path {
  fill: #ffffff !important;
}

.pageSide {
  padding-top: 300px;
  display: block;
}

.shrinkPadding {
}

.noneGap {
  grid-gap: 0;
  max-width: 50vw !important;
}

.titleText {
  font-size: 76px !important;
  line-height: 90px !important;
  color: black !important;
  font-family: "Inter", "Arial", sans-serif !important;
}

@media screen and (max-width: 1200px) {
  .description {
    padding: 0 60px !important;
    padding-top: 16px !important;
  }
  .hero h1 {
    max-width: 800px;
    margin: 0 auto !important;
  }
  .titleText {
    font-size: 68px !important;
    line-height: 80px !important;
  }
}
@media screen and (max-width: 991px) {
  .description {
    padding: 0 0px !important;
    padding-top: 16px !important;
  }
  .titleText {
    font-size: 60px !important;
    line-height: 70px !important;
  }
  .pageSide {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .hero h1 {
    max-width: 600px;
    margin: 0 auto !important;
    padding-bottom: 20px !important;
  }
  .description {
    font-size: 20px !important;
  }
  .titleText {
    font-size: 52px !important;
    line-height: 52px !important;
  }
  .pageSide {
    display: none !important;
  }
}
@media screen and (max-width: 450px) {
  .gridWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .description {
    padding-top: 0 !important;
    font-size: 18px !important;
  }
  .buttonSection {
    grid-gap: 15px;
    padding-top: 35px !important;
  }
  .shrinkPadding {
    padding-top: 10px !important;
  }
  .hero h1 {
    padding: 0 !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    max-width: 400px;
    margin: 0 auto !important;
  }
  .titleText {
    font-size: 44px !important;
    line-height: 56px !important;
  }
  .pageSide {
    display: none !important;
  }
  .hero div[class*="Oval"] {
    width: 35px !important;
    height: 35px !important;
  }
  .description {
    max-width: 320px;
    margin: 0 auto !important;
  }
  .buttonSection {
    padding-top: 40px;
  }
  .logotype svg {
    width: 70px !important;
    height: 70px !important;
  }
  .description {
    padding: 0 0px !important;
    padding-top: 10px !important;
  }
  .linkSection {
    padding-top: 20px;
    padding-bottom: 0;
  }
  .content-row .Button {
    margin-left: 10px;
    margin-right: 10px;
    width: 200px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 450px) {
  .hero {
    padding-top: 20px;
  }
  .buttonSection {
    grid-template-columns: 1fr;
  }
  .subDescriptionText {
    font-size: 14px !important;
  }
  .buttonSection > div {
    display: flex;
    justify-content: center !important;
  }
  .pageSide {
    display: none !important;
  }
}

.appLinks {
  display: flex;
  justify-content: center;
  margin: 40px auto 0px;
}

.appLink {
  width: 180px;
  padding: 10px;
  display: block;
  box-sizing: content-box;
}

@media screen and (max-width: 768px) {
  .appLinks {
    display: block;
  }

  .appLink {
    width: 180px;
    margin: 0px auto;
  }
}
