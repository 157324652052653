.hero {
    padding-top: 80px;
    padding-bottom: 83px;
    font-family: "Inter", "Arial", sans-serif;
}
.normalFontFamily {
  font-family: "Inter", "Arial", sans-serif !important;
}
.test {
    position: relative;
    outline: 2px solid red;
    z-index: 999;
}

.imgTop {
  margin-top: 131px;
  line-height: 24px;
  font-size: 24px;
  font-weight: 700;
}
.title {
  font-size: 48px !important;
  line-height: 64px !important;
  font-family: "Inter", "Arial", sans-serif;
}
h1.title {
  color: black !important;
  margin-bottom: 14px !important;
}
.description {
  font-size: 24px !important;
  color: black !important;
}
p.description {
  line-height: 36px !important;
  padding-bottom: 40px !important;
}
.logoText {
  margin-left: 8px;
  vertical-align: middle;
  background-image: linear-gradient(90deg,#f50130,#e00b91);
  -webkit-background-clip:text;
  color: transparent;
}

h1.topNone {
  margin-top: 20px !important;
}

.xsLeft {}

@media screen and (max-width: 991px) {
  .imgTop {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 46px !important;
    line-height: 46px !important;
  }
  .description {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 450px) {
  .hero {
    padding-top: 0 !important;
  }
  .title {
    font-size: 40px !important;
    line-height: 40px !important;
  }
  .description {
    padding-top: 8px !important;
    font-size: 18px !important;
  }
  .logoText {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 575px) {
    .slider {
        margin-top: 60px;
    }
    .xsLeft {
      align-items: start !important;
      order: -1 !important;
    }
    .xsLeft h1 {
      text-align: left !important;
    }
    .xsLeft p {
      text-align: left !important;
    }
    .xsLeft div {
      text-align: left !important;
    }
    .imgTop {
      margin-top: 0;
    }
}
@media screen and (max-width: 450px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .title {
      font-size: 36px !important;
      line-height: 48px !important;
    }
    h1.title {
      margin-bottom: 12px !important;
    }
}