.hero {
  padding-top: 20px;
  padding-bottom: 83px;
  font-family: "Inter", "Arial", sans-serif;
}
.normalFontFamily {
  font-family: "Inter", "Arial", sans-serif;
}
.emaildiscription {
  line-height: 24px !important;
}
p.emaildiscription {
  margin-bottom: 15px !important;
}
.test {
  position: relative;
  outline: 2px solid red;
  z-index: 999;
}

p.bottom {
  padding-bottom: 60px !important;
}

.imgTop {
  margin-top: 100px;
}

h4.topNone {
  margin-top: 20px !important;
  margin-bottom: 15px !important;
  font-weight: 700 !important;
}

.xsLeft {
}

p.topPadding{
  padding-top: 8px !important;
}

p.contactPadding{
  padding-bottom: 37px !important;
}

p.shrinkGrey.fontStyle {
  color: rgb(140,140,140) !important;
  font-size: 14px !important;
}
p.shrinkGrey.fontStyle a {
  color: rgb(140,140,140) !important;
  font-size: 14px !important
}

p.fontStyle {
  font-size: 16px !important;
  color: black !important;
}
.fontStyle {
  font-size: 16px;
  color: black !important;
  font-family: "Inter", "Arial", sans-serif;
}

.titleFontStyle {
  font-size: 16px;
}

.modalBody {
  height: 80vh !important;
  overflow-y: auto;
}

@media screen and (max-width: 991px) {
  .imgTop {
    margin-top: 30px;
  }
}

@media screen and (max-width: 575px) {
  .slider {
    margin-top: 20px;
  }
  .xsLeft {
    align-items: start !important;
  }
  .xsLeft h1 {
    text-align: left !important;
  }
  .xsLeft p {
    text-align: left !important;
  }
  .xsLeft div {
    text-align: left !important;
  }
  .imgTop {
    margin-top: 0;
  }
  p.shrinkGrey.fontStyle {
    line-height: 20px;
    font-size: 14px !important;
  }
  p.shrinkGrey.fontStyle a {
    line-height: 20px;
  }
  .shrinkBlock {
    display: none !important;
  }
}
@media screen and (max-width: 450px) {
  .hero {
    padding-top: 0 !important;
  }
  .gridWrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}
