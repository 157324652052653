.swiperContainer {
    width: 100%;
    height: 100vh;
    max-height: 700px !important;
    overflow: hidden;
    padding-bottom: 0;
}
.swiperWrapper {

}
.swiperContainer .swiperPagination {
    position: absolute;
    transform: none;
    top: 50%;
    right: -25px;
    display: flex;
    flex-direction: column;
}
.swiperContainer div[class*="swiper-slide"] {
    background-color: transparent;
    -webkit-transform: translate3d(0, 0, 0);
}


@media screen and (max-width: 1200px) {
    .swiperContainer {
        max-height: 580px !important;
        overflow: hidden;
        padding-bottom: 0;
    }
    .swiperContainer .swiperPagination {
        right: 25px;
    }
}

@media screen and (max-width: 991px) {
    .swiperContainer {
        max-height: 590px !important;
        overflow: hidden;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .swiperContainer .swiperPagination {
        right: -25px;
    }
}

@media screen and (max-width: 600px) {
    .swiperContainer .swiperPagination {
        position: static !important;
        margin-top: 30px !important;
        flex-direction: row;
        z-index: 999;
        height: 20px;
    }
    .swiperContainer {
        max-height: initial !important;
        height: 100%;
        overflow: hidden;
        padding-top: 40px;
    }
}

@media screen and (max-width: 575px) {
    .swiperContainer {
        padding-bottom: 0;
    }

}


