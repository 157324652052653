
.hero {
    padding-top: 55px;
    padding-bottom: 60px;
    font-family: "Inter", "Arial", sans-serif;
}
.hero h1 {
    max-width: 900px;
    margin: 0 auto !important;
}

.contentRow {
    padding-top: 50px;
}
.description {
    padding: 0 80px !important;
    padding-top: 16px !important;
}
.title {
  font-size: 48px !important;
  line-height: 64px !important;
  font-family: "Inter", "Arial", sans-serif;
}
h2.title {
  color: black !important;
}
.form {
    padding-top: 36px;
}
.socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 13px;
}
.socials p {
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 22px !important;
    text-align: center;
    
    color: #18214D;
}
.socials div[class*="socials"] > div {
    padding-top: 12px;
}
.dark .socials p {
    color: #ffffff !important;
}
@media screen and (max-width: 1199px) {
    .hero h1 {
        max-width: 800px;
        margin: 0 auto !important;
    }
}

@media screen and (max-width: 991px) {
    .description {
        padding: 0 20px !important;
        padding-top: 16px !important;
    }
}
@media screen and (max-width: 768px) {
    .contentRow {
        padding-top: 60px;
    }
    .socials {
      padding-top: 20px;
    }
    .title {
      font-size: 42px !important;
      line-height: 42px !important;
    }
}

@media screen and (max-width: 450px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .title {
      font-size: 30px !important;
      line-height: 42px !important;
    }
    .contentRow {
      padding-top: 0;
    }
    .description {
        padding: 0  !important;
        padding-top: 16px !important;
        max-width: 330px;
        margin: 0 auto !important;
    }
    .hero h1 {
        padding: 0 20px;
    }
   .form {
        padding: 0;
        padding-top: 25px;
    }
    .socials {
        padding-top: 8px;
    }
}