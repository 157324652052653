.saperator {
}
.iconPadding {
  padding-top: 20px;
}
.normalFontFamily {
  font-family: "Inter", "Arial", sans-serif !important;
}
@media screen and (max-width: 768px) {
  .saperator {
    width: 49.9% !important;
  }
}
@media screen and (max-width: 450px) {
  .socialsText {
    font-size: 16px !important;
    font-weight: 900;
    margin-top: 0 !important;
  }
  .iconPadding {
    padding-top: 13px !important;
  }
}
.socialsText {
  font-size: 18px !important;
  margin-top: 15px !important;
  color: black !important;
  font-weight: 700 !important;
}
