.modalFont {
  color: black;
}

.modalFont p {
  color: black;
  font-size: 12px !important;
  font-family: "Inter", "Arial", sans-serif !important;
}

.modalFont h4 {
  margin-top: 6px !important;
  color: black;
  font-size: 14px !important;
  font-family: "Inter", "Arial", sans-serif !important;
}

.modalFont p strong {
  font-size: 13px !important;
  font-family: "Inter", "Arial", sans-serif !important;
}